import React from 'react';
import './Kurslar.css';
import { Card } from 'react-bootstrap';
import YksSayProgram from '../../utils/images/courses/hg-yks-say.png';
import YksEaProgram from '../../utils/images/courses/hg-yks-ea.png';
import MezunProgram from '../../utils/images/courses/hg-mezun-program.png';
import YdtProgram from '../../utils/images/courses/hg-ydt-program.png';
import OrtaOkulProgram from '../../utils/images/courses/hg-ortaokul-hazirlik.png';
import LgsHazirlikProgram from '../../utils/images/courses/hg-lgs-hazirlik.png';
import Lise1SinifProgram from '../../utils/images/courses/9-sinif-program.png';
import Lise2SinifProgram from '../../utils/images/courses/10-sinif-program.png';
import Lise3SaySinifProgram from '../../utils/images/courses/11-sinif-say-program.png';
import Lise3TMSinifProgram from '../../utils/images/courses/11-sinif-tm.png';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';

const courses = [
  {
    id: 1,
    img: [YksSayProgram],
    title: 'YKS Sayısal Programı',
    description: "Bu program, sayısal alanında YKS'ye hazırlanan öğrenciler için Matematik ve Fen Bilimleri derslerine odaklanır."
  },
  {
    id: 2,
    img: [YksEaProgram],
    title: 'YKS Eşit Ağırlık Programı',
    description: "Eşit Ağırlık alanında YKS'ye hazırlanan öğrenciler için bu program, dengeli bir başarı için gerekli olan tüm dersleri kapsar."
  },
  {
    id: 3,
    img: [YdtProgram],
    title: 'YDT Yabancı Dil Programı',
    description: "Yabancı Dil Testi'ne (YDT) hazırlanan öğrenciler için özel olarak hazırlanan bu program, dil bilgisi, okuma-anlama ve sınav teknikleri üzerine yoğunlaşarak başarıyı garantilemeye odaklanıyor."
  },
  {
    id: 4,
    img: [LgsHazirlikProgram],
    title: 'LGS Hazırlık Programı',
    description: "Bu program, Liseye Geçiş Sınavı'na yönelik kapsamlı bir hazırlık sunar. Öğrencilerin sınav stratejileri geliştirmesine ve başarıya ulaşmasına yardımcı olur."
  },
  {
    id: 5,
    img: [MezunProgram],
    title: 'Mezun Özel Programı',
    description: "Mezun öğrenciler için tasarlanmış bu program, eksik kalan konuları tamamlayarak sınavlarda daha yüksek başarı hedefler."
  },
  {
    id: 6,
    img: [OrtaOkulProgram],
    title: 'Ortaokul Hazırlık Programı',
    description: "Ortaokul öncesi sağlam bir temel atmayı amaçlayan bu program, öğrencilerin özgüvenle eğitim hayatına başlamalarını sağlar."
  },
  {
    id: 7,
    img: [Lise1SinifProgram],
    title: '9. Sınıf Programı',
    description: "Lise eğitimine güçlü bir başlangıç yapmayı amaçlayan bu program, öğrencilerin derslerinde sağlam bir temel oluşturur."
  },
  {
    id: 8,
    img: [Lise2SinifProgram],
    title: '10. Sınıf Programı',
    description: "10. sınıfa geçen öğrenciler için hazırlanan bu program, bilgi pekiştirme ve ileriki sınavlara hazırlık sağlar."
  },
  {
    id: 9,
    img: [Lise3SaySinifProgram],
    title: '11. Sınıf Sayısal Programı',
    description: "Sayısal derslerde başarı hedefleyen 11. sınıf öğrencileri için bu program, sınavlara yönelik derinlemesine bir öğrenim sunar."
  },
  {
    id: 10,
    img: [Lise3TMSinifProgram],
    title: '11. Sınıf Türkçe Matematik Programı',
    description: "Bu program, 11. sınıfta Türkçe ve Matematik derslerine yoğunlaşarak öğrencilerin sınavlara en iyi şekilde hazırlanmasını sağlar."
  },
];

const Kurslar = () => {
  return (
    <div className='courses-page'>
      <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
          <h1 className='text-center fw-semibold'>Kurslar</h1>
          <p>sınavlara hazırlık sürecinde öğrencilere güçlü bir akademik destek sunuyoruz. Her öğrencinin bireysel ihtiyaçlarına yönelik olarak hazırlanan programlarımızla, onların bilgi birikimlerini artırmayı ve sınavlarda başarılı olmalarını sağlamayı amaçlıyoruz. Sınavlara yönelik özelleşmiş içeriklerimiz ve yenilikçi eğitim anlayışımızla, öğrencilerimizin potansiyellerini en üst düzeye çıkarmalarına yardımcı oluyoruz.</p>
        </div>
      </header>

      <div className='container py-5'>
        <div className='row g-4'>
            {courses.map((course) => (
              <div key={course.id} className='col-lg-6'>
                <Card className='text-white shadow scale-hover-effect'>
                  <Card.Img src={course.img} />
                </Card>
              </div>
            ))}
        </div>
      </div>
      <div className='bg-dark text-light py-5'>
          <FaqAccordion />
      </div>
    </div>
  )
}

export default Kurslar;