import React from 'react';
import './Blog.css';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Blog1Img from '../../utils/images/blog/blog1.jpg';
import Blog2Img from '../../utils/images/blog/blog2.jpg';
import Blog3Img from '../../utils/images/blog/blog3.jpg';
import Blog4Img from '../../utils/images/blog/erken-kayit-firsati.jpg';

const blogs = [
  {
    id: 1,
    img: [Blog1Img],
    title: 'Sınırlı Kontenjan ve Hemen Kayıt Fırsatı',
    description: 'Hocalara Geldik Yalova olarak, öğrencilerimize sunduğumuz sınırlı kontenjan avantajlarından yararlanma fırsatını kaçırmayın! Yoğun ilgi gören programlarımız, başarıya odaklı eğitim anlayışımız ile kısa sürede dolmaktadır. Siz de bu kaliteli eğitim deneyiminden faydalanmak için hemen kaydolun ve sınavlara hazırlık sürecinizi bizimle planlayın. Öğrencilerimize özel hazırladığımız ders programları ve birebir eğitim fırsatları ile hedeflerinize ulaşmanız artık çok daha kolay. Başarıya giden yolda en güvenilir adımları birlikte atalım!'
  },
  {
    id: 2,
    img: [Blog2Img],
    title: 'Modern Sınıflar, Deneyimli Öğretmenler ve Etkili Ders Programları',
    description: 'Hocalara Geldik Yalova, modern sınıf ortamları, deneyimli öğretmen kadrosu ve etkili ders programları ile eğitimde fark yaratıyor. Teknolojik altyapımız ve yenilikçi eğitim metotlarımız sayesinde öğrencilerimize en kaliteli eğitimi sunmayı hedefliyoruz. Başarının sırrı, doğru planlama ve etkili bir öğretim stratejisinde saklıdır. Bu bilinçle hareket eden kurumumuz, öğrencilerimizin bireysel ihtiyaçlarına uygun, etkili ve interaktif ders içerikleri sunarak öğrenmeyi keyifli hale getiriyor. Başarı merkezimizde sizleri de aramızda görmekten mutluluk duyarız!'
  },
  {
    id: 3,
    img: [Blog3Img],
    title: 'Emeklilere ve Asgari Ücretlilere Özel Dershane Ücretleri',
    description: 'Hep birlikte yaşadığımız Ekonomik Sorunlar çocuklarımızın eğitim öğretim hayatında sorun olmasın diye biz taşın altına elimizi koyduk.Eğitimde fırsat eşitliğini ilke edinmiş Hocalara Geldik olarak Emekli ve Asgari ücretli ailelerimizin çocukları için 50 kişilik bir kontenjan ayırdık.45.000₺ üstelik 10 taksit fırsatı ile kaydını yaptırmak için acele et'
  },
  {
    id: 4,
    img: [Blog4Img],
    title: 'Yalova’da YKS Başarısı İçin Erken Kayıt Fırsatı!',
    description: 'Yalova\'da üniversiteye hazırlık sürecinde sizlere en iyi eğitimi sunmak için buradayız. Öğrencilerimizin başarısını ön planda tutarak, kişiye özel programlarımız ve sunduğumuz destek hizmetleriyle fark oluşturuyoruz. Şimdi üniversite sınavına hazırlanmak için erken kayıt fırsatlarımız ile birlikte sizleri kurumumuza bekliyoruz.'
  },
];


const Blog = () => {
  return (
    <div className='blog-page'>
      <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
          <h1 className='text-center fw-semibold'>Blog</h1>
          <p className='text-center w-75 mb-5'>eğitim dünyasına dair en güncel gelişmeleri, sınavlara hazırlık tüyolarını ve öğrenci başarısını artırmaya yönelik ipuçlarını bulabilirsiniz. Uzman eğitmenlerimizin kaleme aldığı makaleler ve rehber yazılar, öğrencilerimize ve velilerimize hem akademik hem de kişisel gelişim konularında destek sağlamayı amaçlıyor. Eğitim yolculuğunuzda sizlere rehberlik edecek bilgi dolu içeriklerimizle, her zaman yanınızdayız.</p>
        </div>
      </header>
      <div className='bg-body-tertiary py-5'>
        <div className='container'>
          <div className="row g-4">
              {blogs.map((blog) => (
                <div key={blog.id} className='col-md-6 col-lg-4'>
                  <Link to="/blog" className='text-decoration-none'>
                    <Card className='h-100 shadow scale-hover-effect bg-dark text-light border-0'>
                      <Card.Img variant='top' src={blog.img} alt='' />
                      <Card.Body className='p-md-5 d-flex flex-column align-items-center'>
                        <Card.Title className='fs-2 mb-4'>{blog.title}</Card.Title>
                        <Card.Text className='text-center'>{blog.description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog;