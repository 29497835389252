import React from "react";
import "./Anasayfa.css";
import { Link } from "react-router-dom";
import ChooseSection from "../../components/ChooseSection/ChooseSection";
import vipGroup from "../../utils/images/courses/hg-vip-grup.jpg";
import FaqAccordion from "../../components/FaqAccordion/FaqAccordion";
import { Card } from "react-bootstrap";
import Blog1Img from "../../utils/images/blog/blog1.jpg";
import Blog2Img from "../../utils/images/blog/blog2.jpg";
import Blog3Img from "../../utils/images/blog/blog3.jpg";

const blogs = [
  {
    id: 1,
    img: [Blog1Img],
    title: "Sınırlı Kontenjan ve Hemen Kayıt Fırsatı",
    description:
      "Hocalara Geldik Yalova olarak, öğrencilerimize sunduğumuz sınırlı kontenjan avantajlarından yararlanma fırsatını kaçırmayın! Yoğun ilgi gören programlarımız, başarıya odaklı eğitim anlayışımız ile kısa sürede dolmaktadır. Siz de bu kaliteli eğitim deneyiminden faydalanmak için hemen kaydolun ve sınavlara hazırlık sürecinizi bizimle planlayın. Öğrencilerimize özel hazırladığımız ders programları ve birebir eğitim fırsatları ile hedeflerinize ulaşmanız artık çok daha kolay. Başarıya giden yolda en güvenilir adımları birlikte atalım!",
  },
  {
    id: 2,
    img: [Blog2Img],
    title: "Modern Sınıflar, Deneyimli Öğretmenler ve Etkili Ders Programları",
    description:
      "Hocalara Geldik Yalova, modern sınıf ortamları, deneyimli öğretmen kadrosu ve etkili ders programları ile eğitimde fark yaratıyor. Teknolojik altyapımız ve yenilikçi eğitim metotlarımız sayesinde öğrencilerimize en kaliteli eğitimi sunmayı hedefliyoruz. Başarının sırrı, doğru planlama ve etkili bir öğretim stratejisinde saklıdır. Bu bilinçle hareket eden kurumumuz, öğrencilerimizin bireysel ihtiyaçlarına uygun, etkili ve interaktif ders içerikleri sunarak öğrenmeyi keyifli hale getiriyor. Başarı merkezimizde sizleri de aramızda görmekten mutluluk duyarız!",
  },
  {
    id: 3,
    img: [Blog3Img],
    title: "Emeklilere ve Asgari Ücretlilere Özel Dershane Ücretleri",
    description:
      "Hep birlikte yaşadığımız Ekonomik Sorunlar çocuklarımızın eğitim öğretim hayatında sorun olmasın diye biz taşın altına elimizi koyduk.Eğitimde fırsat eşitliğini ilke edinmiş Hocalara Geldik olarak Emekli ve Asgari ücretli ailelerimizin çocukları için 50 kişilik bir kontenjan ayırdık.45.000₺ üstelik 10 taksit fırsatı ile kaydını yaptırmak için acele et",
  },
];

const Anasayfa = () => {
  return (
    <div className="home-page">
      <header className="h-100 min-vh-100 d-flex align-items-center text-light">
        <div className="container d-flex flex-column align-items-center">
          <h2>Yalova'nın</h2>
          <h1>En İyi Eğitim Kurumu</h1>
          <p>
            Yalova'nın kalbinde, geleceğin liderlerini yetiştiren, eğitimde
            mükemmeliyetin simgesi olmayı sürdürüyor. Alanında uzman eğitmenleri
            ve yenilikçi eğitim yöntemleriyle, her yaş grubundan öğrenciler için
            en kaliteli öğrenme deneyimini sunuyoruz. Eğitimdeki sınırları
            zorlayan yaklaşımımızla, bireylerin potansiyellerini en üst düzeye
            çıkararak, onları başarıya giden yolda güçlü adımlarla ilerlemeye
            teşvik ediyoruz. Siz de Yalova'nın en prestijli eğitim merkezinde
            yerinizi alın ve geleceğiniz için en doğru adımı atın!
          </p>
          <div className="d-flex flex-column flex-sm-row align-items-center">
            <Link to="/kurslar">
              <button
                type="button"
                className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
              >
                Kurslar
              </button>
            </Link>
            <Link to="/iletisim">
              <button
                type="button"
                className="btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
              >
                Başarıya Ulaşmak İçin Bize Katıl
              </button>
            </Link>
          </div>
        </div>
      </header>
      <div className="py-5">
        <ChooseSection />
      </div>
      <div className="py-5 bg-light">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-around">
            <div className="col-lg-5">
              <h2 className="text-capitalize">vip gruplarımızla başlıyoruz</h2>
              <ul>
                <li>4-6 Kişilik Sınıflar</li>
                <li>Birebir Koç Takibi</li>
                <li>Haftada 48 Saat Eğitim</li>
                <li>Haftada 2 Saat Birebir Özel Ders</li>
                <li>Günlük Soru Çözüm Saatleri</li>
                <li>Gece Etütleri</li>
              </ul>
              <Link to="/kurslar">
                <button
                  type="button"
                  className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
                >
                  DAHA FAZLASI
                </button>
              </Link>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0">
              <img src={vipGroup} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <FaqAccordion />
      </div>
      <div className="blog-section text-light py-5">
        <div className="container d-flex flex-column align-items-center">
          <h2 className="text-center text-capitalize mb-5">güncel blog</h2>
          <div className="row g-4">
            {blogs.map((blog) => (
              <div key={blog.id} className="col-md-6 col-lg-4">
                <Link to="/blog" className="text-decoration-none">
                  <Card className="h-100 shadow scale-hover-effect">
                    <Card.Img variant="top" src={blog.img} />
                    <Card.Body className="p-md-5">
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>{blog.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </div>
            ))}
          </div>
          <Link to="/blog">
            <button type="button" className="btn btn-primary btn-lg mt-5">
              DAHA FAZLASI
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Anasayfa;
