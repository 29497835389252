import React from 'react';
import './Iletisim.css';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import EtutOdasi from '../../utils/images/gallery/etut-odasi.jpg';
import Kutuphane from '../../utils/images/gallery/kutuphane.jpg';
import Sinif from '../../utils/images/gallery/sinif.jpg';

const Iletisim = () => {
  return (
    <div className='contact-page'>
      <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
          <h1 className='text-center fw-semibold'>Birlikte Başarıya Ulaşalım.</h1>
          <p className='text-center w-75 mb-5'>Hedeflerinize ulaşmak için attığınız her adımda, yanınızdayız! Sorularınız, merak ettikleriniz ya da kariyerinizde bir sonraki büyük sıçramayı yapmanız için gereken her şeyde size rehberlik etmeye hazırız. Başarınıza giden yolda ilk adımı şimdi atın!</p>
        </div>
      </header>
      <div className='container my-5 d-flex justify-content-center'>
        <Form id='contact-form' action='https://api.yalovahocalarageldik.com/api/pull' method='post'>
          <Row className='mb-3'>
            <Col sm={12} md={6} className='mb-3 mb-md-0'>
              <Form.Label>Adın*</Form.Label>
              <Form.Control placeholder='Adın' name='name' />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Soyadın*</Form.Label>
              <Form.Control placeholder='Soyadın' name='surname' />
            </Col>
          </Row>
          <Form.Group className='mb-3'>
            <Form.Label>Telefon Numaran*</Form.Label>
            <Form.Control type='tel' placeholder='Telefon Numaran' name='phone' />
            <Form.Text className='text-muted'>
              Telefon numaranızı hiç kimseyle paylaşmayacağız..
            </Form.Text>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Adresin</Form.Label>
            <Form.Control placeholder='Adresin' name='address'></Form.Control>
          </Form.Group>
          <Row className='mb-3'>
            <Col sm={12} md={6} className='mb-3 mb-md-0'>
              <Form.Label>Kayıt Olmak İstediğin Kurs</Form.Label>
              <Form.Select defaultValue={"YKS Sayısal"} name='course_type'>
                <option>YKS Sayısal</option>
                <option>YKS Eşit Ağırlık</option>
                <option>DGS/ALES</option>
                <option>MSÜ/ÖSYM</option>
                <option>Mezun</option>
              </Form.Select>
            </Col>
          </Row>

          <Form.Group className='mb-3'>
              <Form.Label>Mesajın</Form.Label>
              <Form.Control as="textarea" rows={3} name='message' />
          </Form.Group>

          <Button variant="primary btn-lg" type='submit'>Formu Gönder</Button>
        </Form>
      </div>
      <div className='bg-dark text-light p-5'>
        <div className='container'>
          <h2 className='text-center mb-5'>Kurumumuz</h2>
          <div className='row g-4'>
            <div className='col-lg-4 d-flex flex-column align-items-center'>
              <img src={EtutOdasi} className='img-fluid' alt="" />
              <h3 className='text-center mt-3'>Etüt Odalarımız</h3>
            </div>
            <div className='col-lg-4 d-flex flex-column align-items-center'>
              <img src={Kutuphane} className='img-fluid' alt="" />
              <h3 className='text-center mt-3'>Kütüphanemiz</h3>
            </div>
            <div className='col-lg-4 d-flex flex-column align-items-center'>
              <img src={Sinif} className='img-fluid' alt="" />
              <h3 className='text-center mt-3'>Sınıflarımız</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Iletisim;
