import React from "react";
import "./FormNotSent.css";

const FormNotSent = () => {
  return (
    <div className="about-page">
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Form Gönderilemedi</h1>
          <p>Form Gönderilemedi. Site yönetimine başvurun.</p>
        </div>
      </header>
    </div>
  );
};

export default FormNotSent;
