import React from "react";
import "./FormSent.css";

const FormSent = () => {
  return (
    <div className="about-page">
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Form Gönderildi</h1>
          <p>Form Başarıyla Gönderildi.</p>
        </div>
      </header>
    </div>
  );
};

export default FormSent;
