import React from 'react';
import './Hakkimizda.css';
import { Link } from 'react-router-dom';
import AboutUsSectionImg from '../../utils/images/background/hg-about.section.jpg';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import Star1 from '../../utils/images/stars/student1.jpg';
import Star2 from '../../utils/images/stars/student2.jpg';
import Star3 from '../../utils/images/stars/student3.jpg';
import Star4 from '../../utils/images/stars/student4.jpg';
import Star5 from '../../utils/images/stars/student5.jpg';
import Star6 from '../../utils/images/stars/student6.jpg';
import Star7 from '../../utils/images/stars/student7.jpg';
import Star8 from '../../utils/images/stars/student8.jpg';
import Star9 from '../../utils/images/stars/student9.jpg';
import Star10 from '../../utils/images/stars/student10.jpg';
import Star11 from '../../utils/images/stars/student12.jpg';
import Star12 from '../../utils/images/stars/student12.jpg';
import Star13 from '../../utils/images/stars/student13.jpg';
import Star14 from '../../utils/images/stars/student14.jpg';
import Star15 from '../../utils/images/stars/student15.jpg';
import Star16 from '../../utils/images/stars/student16.jpg';
import Star17 from '../../utils/images/stars/student17.jpg';
import Star18 from '../../utils/images/stars/student18.jpg';
import Star19 from '../../utils/images/stars/student19.jpg';
import Star20 from '../../utils/images/stars/student20.jpg';
import Star21 from '../../utils/images/stars/student21.jpg';

const stars = [
  {
    id: 1,
    img: [Star1]
  },
  {
    id: 2,
    img: [Star2]
  },
  {
    id: 3,
    img: [Star3]
  },
  {
    id: 4,
    img: [Star4]
  },
  {
    id: 5,
    img: [Star5]
  },
  {
    id: 6,
    img: [Star6]
  },
  {
    id: 7,
    img: [Star7]
  },
  {
    id: 8,
    img: [Star8]
  },
  {
    id: 9,
    img: [Star9]
  },
  {
    id: 10,
    img: [Star10]
  },
  {
    id: 11,
    img: [Star11]
  },
  {
    id: 12,
    img: [Star12]
  },
  {
    id: 13,
    img: [Star13]
  },
  {
    id: 14,
    img: [Star14]
  },
  {
    id: 15,
    img: [Star15]
  },
  {
    id: 16,
    img: [Star16]
  },
  {
    id: 17,
    img: [Star17]
  },
  {
    id: 18,
    img: [Star18]
  },
  {
    id: 19,
    img: [Star19]
  },
  {
    id: 20,
    img: [Star20]
  },
  {
    id: 21,
    img: [Star21]
  },
];

const Hakkimizda = () => {
  return (
    <div className='about-page'>
      <header className='height-75'>
        <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
          <h1 className='text-center fw-semibold'>Hakkımızda</h1>
          <p>Hocalara Geldik Yalova, lise ve üniversite sınavlarına hazırlık kursları ve ders takviyesi hizmeti sunan bir eğitim merkezidir.</p>
        </div>
      </header>
      <div className='container my-5'>
        <div className='row'>
          <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
            <h2 className='mb-4 mb-lg-5'>Yalova’nın Lider Eğitim Merkezi: Hocalara Geldik</h2>
            <p>Yalova’nın en seçkin eğitim merkezlerinden biri olan Hocalara Geldik Yalova Şubesi, öğrencilere benzersiz bir öğrenme deneyimi sunmayı amaçlamaktadır. Deneyimli ve alanında uzman öğretmen kadromuzla, öğrencilerimizin eğitim ihtiyaçlarına en iyi şekilde yanıt veriyoruz. Sınavlara yönelik hazırlık programlarımızla, LGS’den YKS’ye kadar her seviyedeki öğrencinin başarıya ulaşmasını hedefliyoruz. Hocalara Geldik, sadece akademik başarıyı artırmayı değil, aynı zamanda öğrencilere öğrenmeyi sevdirmeyi, öz güvenlerini yükseltmeyi ve sınav stresini yönetmeyi de önemsemektedir.  Başarı Merkezleri Çatısı altında faaliyet gösteren merkezimiz, deneyimli ve uzman kadrosu, modern ve donanımlı sınıfları, kişiye özel eğitim programları ve motivasyon odaklı eğitim anlayışı ile öğrencilere en iyi eğitimi vermeyi amaçlamaktadır.</p>
            <p>Alanında uzman ve deneyimli öğretmen kadromuzla, her öğrencimize en iyi eğitimi sunuyoruz. Öğrencilerimize özel eğitim programları hazırlayarak, onların en iyi şekilde öğrenmelerini sağlıyoruz. Modern ve donanımlı sınıflarımızda, en iyi eğitim teknolojilerini kullanarak, öğrencilere çağdaş bir eğitim ortamı sunuyoruz. Eğitimde motivasyonu ön planda tutarak, öğrencilerimizin başarsını desteklemeye odaklanıyoruz. Geçmiş yıllarda birçok öğrencimizi istedikleri okullara yerleştirerek, başarılı sonuçlar elde ettik.</p>
            <p className='mb-4 mb-lg-5'>Yalova şubemiz, modern sınıfları, interaktif ders materyalleri ve bireysel rehberlik hizmetleri ile fark yaratmaktadır. Her öğrencinin kendine özgü öğrenme hızını ve tarzını dikkate alarak, kişiye özel eğitim stratejileri geliştiriyoruz. Öğrencilerimizin potansiyellerini en iyi şekilde kullanmalarını sağlayacak güçlü bir eğitim altyapısı sunarak, onların geleceğe güvenle adım atmalarını destekliyoruz. Eğitimde kaliteyi ve başarıyı bir araya getiren Hocalara Geldik Yalova Şubesi, sizin için en doğru adres!</p>
            <h3>Misyonumuz</h3>
            <p>Hocalara Geldik Yalova olarak misyonumuz, öğrencilerimize başarı odaklı, kaliteli ve modern bir eğitim deneyimi sunmaktır. Eğitim sürecinde öğrencilerin bireysel potansiyellerini en üst seviyeye çıkarmayı hedeflerken, onlara sağlam bir akademik temel kazandırmak için özel programlar ve özverili bir rehberlik sunuyoruz. Öğrencilerimizin sadece sınavlarda değil, hayat boyu karşılaşacakları tüm zorluklarda başarılı olmalarını sağlamak amacıyla, donanımlı öğretmen kadromuzla kişiye özgü destekleyici çözümler sunuyoruz.</p>
            <h3>Vizyonumuz</h3>
            <p>Vizyonumuz, Hocalara Geldik Yalova’yı bölgenin en güvenilir ve en başarılı eğitim merkezi haline getirmektir. Geleceğin liderlerini yetiştirme sorumluluğuyla, öğrencilerimizin hayal ettikleri üniversiteleri kazanmalarını ve hedeflerine ulaşmalarını sağlamak için yenilikçi yöntemler ve teknolojiye dayalı bir eğitim sunuyoruz. Vizyonumuzun temelinde; öğrencilerimizin akademik başarılarını sürekli olarak arttırmak ve topluma faydalı bireyler olarak yetişmelerine katkıda bulunmak yer alıyor.</p>
            <h2>Hocalara Geldik Yalova'da Sunulan Kurslar</h2>
            <ul>
              <li>TYT/AY Hazırlık Kursları</li>
              <li>DGS/ALES Hazırlık Kursları</li>
              <li>MSÜ/ÖSYM Hazırlık Kursları</li>
              <li>Temel Lise Dersleri</li>
            </ul>
            <Link to="/iletisim">
              <button type='button' className='btn btn-primary btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>İletişim</button>
            </Link>
          </div>
          <div className='col-lg-6 d-flex justify-content-center'>
            <img src={AboutUsSectionImg} className='img-fluid' alt='' />
          </div>
        </div>
      </div>
      <div className='bg-dark text-light py-5'>
        <ChooseSection />
      </div>
      <div className='bg-body-tertiary py-5'>
        <div className="container">
          <h2 className='text-center mb-5'>Birlikte Başardık</h2>
          <div className='row g-4'>
            {stars.map((star) => (
              <div key={star.id} className='col-md-4'>
                <img src={star.img} className='img-fluid' alt=''/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hakkimizda