import React from 'react';
import './FaqAccordion.css';
import { Accordion } from 'react-bootstrap';

function FaqAccordion() {
    return (
        <div className='faq-section'>
            <div className='container d-flex flex-column align-items-center'>
                <h2 className='text-center text-capitalize mb-5'>En sık sorulan sorular</h2>
                <p className='text-center mb-5'>
                    Aklınıza takılan sorulara hızlı ve kolayca yanıt bulmak için doğru yerdesiniz. Aşağıda, sıkça sorulan sorulara verilen detaylı cevaplarla, merak ettiğiniz konulara açıklık getirmeyi amaçlıyoruz. Sorularınıza dair en güncel ve doğru bilgiyi sağlamak için hazırladığımız bu bölüm, karşılaştığınız her türlü belirsizliği gidermeye yardımcı olacaktır. Hızlı bir rehber olarak bu alanı kullanarak, öğrenme sürecinizdeki adımları güvenle atabilirsiniz.
                </p>
                <Accordion defaultActivateKey="" flush>
                    <Accordion.Item eventKey='0'>
                        <Accordion.Header>Öğrencilerin Başarıları Nasıl Değerlendiriliyor?</Accordion.Header>
                        <Accordion.Body>
                            Öğrencilerimizin başarılarını sürekli olarak izler ve değerlendirme süreçleriyle ilerlemelerini takip ederiz. Bu değerlendirmeler sayesinde, öğrencilerimizin hangi alanlarda daha fazla desteğe ihtiyaç duyduğunu belirler ve onları hedeflerine ulaştıracak stratejiler geliştiririz.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='1'>
                        <Accordion.Header>Velilerin Eğitim Sürecine Katılımı Nasıl Sağlanıyor?</Accordion.Header>
                        <Accordion.Body>
                            Velilerle düzenli olarak yapılan toplantılar ve kişisel geri bildirim seansları sayesinde, öğrencinin gelişimi sürekli takip edilir. Velilerimize, çocuklarının eğitim yolculuğunda aktif bir rol üstlenmeleri için rehberlik sunarız.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='2'>
                        <Accordion.Header>Öğrenci Koçluğu Nasıl İşler?</Accordion.Header>
                        <Accordion.Body>
                            Öğrenci koçlarımız, bireysel başarı planları oluşturarak öğrencilerin akademik ve kişisel gelişimlerini destekler. Bu süreçte, hedef belirleme, zaman yönetimi ve motivasyon gibi konular üzerinde durularak öğrencinin bütünsel gelişimi sağlanır.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='3'>
                        <Accordion.Header>Eğitmenler Kimlerdir?</Accordion.Header>
                        <Accordion.Body>
                            Görev yapan eğitmenler, alanlarında uzman ve deneyimli öğretmenlerdir. Her biri, öğrencilerimizin akademik başarısını en üst düzeye çıkarmak için titizlikle seçilmiştir.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='4'>
                        <Accordion.Header>Eğitim Saatleri Nasıl Düzenleniyor?</Accordion.Header>
                        <Accordion.Body>
                            Eğitim saatleri, öğrencilerin okul programlarına ve bireysel ihtiyaçlarına göre esnek bir şekilde düzenlenmektedir. Haftalık ders programları, öğrencilerin en verimli saatlerinde eğitim alabilmeleri için kişiye özel olarak planlanır.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='5'>
                        <Accordion.Header>Öğrencilere Sunulan Ekstra Kaynaklar Neler?</Accordion.Header>
                        <Accordion.Body>
                            Öğrencilerimizin ihtiyaçlarına yönelik zengin bir kaynak yelpazesi sunuyoruz. Özel çalışma kitapları, kişisel özel sorubankaları ve interaktif testler ile öğrencilerimizin öğrenme sürecini destekliyoruz. Bu ek materyaller, öğrencilerimizin konuları daha iyi anlamalarını ve sınavlara daha iyi hazırlanmalarını sağlar.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='6'>
                        <Accordion.Header>Sınav Hazırlığı Konusunda Nasıl Bir Destek Sağlıyor?</Accordion.Header>
                        <Accordion.Body>
                            Sınavlara hazırlık sürecinde, öğrencilerimize yönelik özel programlar ve deneme sınavları düzenliyoruz. Amacımız, sınav stresiyle başa çıkmalarını sağlamak ve onları sınavlara en iyi şekilde hazırlamaktır.
                            </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey='7'>
                        <Accordion.Header>Deneme Sınavları Yapılıyor mu?</Accordion.Header>
                        <Accordion.Body>
                            Evet, düzenli aralıklarla deneme sınavları yapılmaktadır. Bu sınavlar, öğrencilerimizin bilgi seviyesini ve sınavlara olan hazırlık durumunu ölçmek amacıyla gerçekleştirilmektedir.
                            </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default FaqAccordion;