import React from 'react';
import { Card } from 'react-bootstrap';

function ChooseSection() {
    return (
        <div>
            <div className="container">
                <h2 className='text-center mb-5'>Neden Hocalara Geldik - Yalova'yı Seçmelisiniz?</h2>
                <div className='row g-4'>
                    <div className='col-lg-4'>
                        <Card className='d-flex align-items-center border-0 h-100'>
                            <div className='mt-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" class="bi bi-trophy-fill" viewBox="0 0 16 16">
                                    <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935" />
                                </svg>
                            </div>
                            <Card.Body>
                                <Card.Title className='text-center text-capitalize mb-3'>
                                    Kişiye Özel Eğitim Yaklaşımlarıyla Başarıya Ulaşın
                                </Card.Title>
                                <Card.Text className='text-center'>
                                    Her öğrencinin farklı öğrenme hızına ve stiline sahip olduğunu biliyoruz. Bu yüzden, size özel olarak hazırlanan eğitim yöntemleri ve stratejilerle, güçlü yanlarınızı ortaya çıkarıyor ve başarıya giden yolda size rehberlik ediyoruz.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-lg-4'>
                        <Card className='d-flex align-items-center border-0 h-100'>
                            <div className='mt-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                                </svg>
                            </div>
                            <Card.Body>
                                <Card.Title className='text-center text-capitalize mb-3'>
                                    Sürekli Güncellenen Eğitim İçerikleriyle Çağı Yakalayın
                                </Card.Title>
                                <Card.Text className='text-center'>
                                    Ders içeriklerimiz, sürekli olarak yenilenen ve güncellenen bilgi ve teknolojilere dayanır. Böylece, her zaman en güncel ve çağdaş bilgilere erişir, değişen dünyaya ayak uydurabilme becerisi kazanırsınız
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-lg-4'>
                        <Card className='d-flex align-items-center border-0 h-100'>
                            <div className='mt-3'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="#dc3545" class="bi bi-hourglass-top" viewBox="0 0 16 16">
                                    <path d="M2 14.5a.5.5 0 0 0 .5.5h11a.5.5 0 1 0 0-1h-1v-1a4.5 4.5 0 0 0-2.557-4.06c-.29-.139-.443-.377-.443-.59v-.7c0-.213.154-.451.443-.59A4.5 4.5 0 0 0 12.5 3V2h1a.5.5 0 0 0 0-1h-11a.5.5 0 0 0 0 1h1v1a4.5 4.5 0 0 0 2.557 4.06c.29.139.443.377.443.59v.7c0 .213-.154.451-.443.59A4.5 4.5 0 0 0 3.5 13v1h-1a.5.5 0 0 0-.5.5m2.5-.5v-1a3.5 3.5 0 0 1 1.989-3.158c.533-.256 1.011-.79 1.011-1.491v-.702s.18.101.5.101.5-.1.5-.1v.7c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13v1z" />
                                </svg>
                            </div>
                            <Card.Body>
                                <Card.Title className='text-center text-capitalize mb-3'>
                                    Etkili Zaman Yönetimi ile Verimliliği Artırın
                                </Card.Title>
                                <Card.Text className='text-center'>
                                    Eğitim sürecinde etkili zaman yönetimi büyük önem taşır. Programlarımız, ders çalışma düzeninizi optimize ederek, zamanınızı en verimli şekilde kullanmanızı sağlar ve hedeflerinize en kısa sürede ulaşmanızı destekler.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseSection;
