import "./App.css";
import { Link, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Anasayfa from "./sayfalar/Anasayfa/Anasayfa";
import Kurslar from "./sayfalar/Kurslar/Kurslar";
import Hakkimizda from "./sayfalar/Hakkimizda/Hakkimizda";
import Blog from "./sayfalar/Blog/Blog";
import FormSent from "./sayfalar/FormSent/FormSent";
import FormNotSent from "./sayfalar/FormNotSent/FormNotSent";
import Iletisim from "./sayfalar/Iletisim/Iletisim";
import logo from "../src/utils/images/logo/hg-circle-logo.png";
import { Image } from "react-bootstrap";

function App() {
  return (
    <div>
      <Navbar expand="lg" className="position-absolute w-100">
        <Container>
          <Navbar.Brand>
            <Link to="/" className="navbar-brand d-flex align-items-center">
              <Image src={logo} width={100} height={100} alt="" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="bg-light"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto justify-content-end w-100">
              <Nav.Link href="/" className="text-uppercase">
                Anasayfa
              </Nav.Link>
              <Nav.Link href="/kurslar" className="text-uppercase">
                Kurslar
              </Nav.Link>
              <Nav.Link href="/hakkimizda" className="text-uppercase">
                Hakkımızda
              </Nav.Link>
              <Nav.Link href="/blog" className="text-uppercase">
                Blog
              </Nav.Link>
              <Nav.Link href="/iletisim" className="text-uppercase">
                İletişim
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Anasayfa />} />
        <Route path="/kurslar" element={<Kurslar />} />
        <Route path="/hakkimizda" element={<Hakkimizda />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/iletisim" element={<Iletisim />} />
        <Route path="/formsent" element={<FormSent />} />
        <Route path="/formnotsent" element={<FormNotSent />} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
      </Routes>

      <footer>
        <div className="container my-5">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-4">
              <Link to="/iletisim">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-lg mb-5 mb-md-4"
                >
                  Takipte Kal
                </button>
              </Link>
              <ul className="footer-social-icons list-unstyled d-flex justify-content-between">
                <Link to="https://www.facebook.com/Hocalarageldikyalova/">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#2c5fa9"
                      class="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                    </svg>
                  </li>
                </Link>
                <Link to="https://www.instagram.com/hocalarageldikyalova">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#2c5fa9"
                      class="bi bi-instagram"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                    </svg>
                  </li>
                </Link>
                <Link to="https://www.tiktok.com/@hgyalova">
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      fill="#2c5fa9"
                      class="bi bi-tiktok"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                    </svg>
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-md-7 col-lg-6">
              <div className="d-row d-md-flex justify-content-between align-items-center">
                <div className="col-12 col-md-6 col-lg-5 mb-5 mt-4 my-md-0">
                  <ul className="footer-navigation list-unstyled mb-0">
                    <Link to="/" className="text-decoration-none text-dark">
                      <li className="text-uppercase fw-semibold">Anasayfa</li>
                    </Link>
                    <Link
                      to="/kurslar"
                      className="text-decoration-none text-dark"
                    >
                      <li className="text-uppercase fw-semibold">Kurslar</li>
                    </Link>
                    <Link
                      to="/hakkimizda"
                      className="text-decoration-none text-dark"
                    >
                      <li className="text-uppercase fw-semibold">Hakkımızda</li>
                    </Link>
                    <Link to="/blog" className="text-decoration-none text-dark">
                      <li className="text-uppercase fw-semibold">Blog</li>
                    </Link>
                    <Link
                      to="/iletisim"
                      className="text-decoration-none text-dark"
                    >
                      <li className="text-uppercase fw-semibold">İletişim</li>
                    </Link>
                  </ul>
                </div>
                <div className="col-12 col-md-6 col-lg-7">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <p>
                        ADRES - Fevzi Çakmak Mahallesi Cumhuriyet Caddesi No73
                        Civil Mağaza Üstü Hocalara Geldik Başarı Merkezi Yalova
                      </p>
                    </li>
                    <li>
                      <p>TELEFON - (0226) 811 42 17</p>
                    </li>
                    <li>
                      <p>Email - yalova@basarimerkezleri.com</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-body-tertiary">
          <div className="container">
            <p className="p-3 m-0 text-center">
              © 2024 Hocalara Geldik Başarı Merkezleri. Tüm hakları saklıdır.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
